import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

type ImageProps = {
  remoteImage: ImageDataLike;
  fallbackImageUrl: string;
  alt: string;
};

const Image: React.FC<ImageProps> = ({
  remoteImage,
  fallbackImageUrl,
  alt,
}) => {
  const image = getImage(remoteImage);

  return (
    <>
      {image ? (
        <GatsbyImage image={image} alt={alt} />
      ) : (
        <img src={fallbackImageUrl} alt={alt} loading="lazy" />
      )}
    </>
  );
};

export default Image;
