import React from "react";
import { Typography, Button } from "@novaescola/eva-react-v2";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/general/Seo";
import BreadCrumb from "../components/general/Breadcrumb";
import Image from "../components/general/Image";

import Menu from "../components/detalhes/Menu";
import { PaginaAgregadora } from "../components/detalhes/_/types";
import Livro from "../components/detalhes/Livro";
import MaterialComplementar from "../components/detalhes/MaterialComplementar";

const TEACHER = "professor";
const STUDENT = "aluno";

type PaginaAgregadoraProps = {
  paginaAgregadora: PaginaAgregadora;
};

type PaginaAgregadoraPageProps = {
  data: PaginaAgregadoraProps;
};

const PaginaAgregadoraPage: React.FC<PaginaAgregadoraPageProps> = ({
  data,
}) => {
  const { titulo, subtitulo, descricao, metadata, livros, materiais, curso } =
    data.paginaAgregadora;

  const livrosProfessor = livros.filter((livro) => livro.tipo === TEACHER);
  const livrosAluno = livros.filter((livro) => livro.tipo === STUDENT);
  const materiaisProfessor = materiais.filter(
    (material) => material.tipo === TEACHER
  );
  const materiaisAluno = materiais.filter(
    (material) => material.tipo === STUDENT
  );

  return (
    <Layout heroClass="no-hero" headerClass="padding-header">
      <Seo title={metadata.tituloSeo} description={metadata.descricaoSeo} />
      <section className="container-fluid title-section">
        <div className="container d-flex fd-column">
          <BreadCrumb
            items={[
              { text: "Material Educacional", url: "/" },
              { text: "Catálogo de Materiais", url: "/catalogo" },
              { text: titulo },
            ]}
          />

          <Typography variant="title" size={4} component="h1" className="eva-margin-bottom-2">
            {titulo}
          </Typography>
          <Typography variant="body" size={1} component="h2" className="ws-pre-wrap eva-margin-bottom-5">
            {subtitulo}
          </Typography>
        </div>
      </section>

      <section className="container-fluid eva-margin-top-2 about-section">
        <div className="container d-flex fd-column">
          <div className="row eva-margin-bottom-3">
            <div className="col-lg-6 col-md-6">
              <div className="row cross-info">
                <aside className="col-lg-6 d-flex jc-center">
                  <nav className="menu-aside eva-margin-bottom-4">
                    <Menu
                      secaoProfessor={
                        livrosProfessor && livrosProfessor.length > 0
                      }
                      secaoAluno={livrosAluno && livrosAluno.length > 0}
                      secaoCurso={!!curso}
                      titulo={titulo}
                      media={metadata.capa}
                    />
                  </nav>
                </aside>

                <div className="col-lg-6 d-flex jc-center">
                  <Image
                    remoteImage={metadata.remoteImage}
                    fallbackImageUrl={metadata.capa}
                    alt={metadata.capaAltText}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6" id="about-section">
              <Typography variant="title" size={6} component="h2" className="eva-margin-bottom-1">
                Sobre o material
              </Typography>
              <Typography variant="body" size={2} className="ws-pre-wrap">
                {descricao}
              </Typography>
            </div>
          </div>

          <div className="row eva-margin-bottom-2">
            <div className="col-lg-9 col-md-9 offset-md-3">
              <div className="audima">
                <div id="audimaWidget" />
                <script src="https://audio4.audima.co/audima-widget.js"></script>
              </div>
            </div>
          </div>
        </div>
      </section>

      {livrosProfessor.length > 0 && (
        <section className="container-fluid eva-margin-top-2 teacher-section">
          <div className="container d-flex fd-column" id="teacher-section">
            <div className="col-lg-8 col-md-8 offset-md-3">
              <Typography variant="title" size={6} component="h2" className="eva-margin-bottom-1-5">
                Livros do professor
              </Typography>

              <div className="eva-card eva-bg-branco">
                <div className="eva-card-info">
                  <div className="livro">
                    <table className="table">
                      <tbody>
                        {livrosProfessor.map(
                          ({
                            id,
                            titulo,
                            formato,
                            paginas,
                            tipo,
                            metadata,
                            componentes,
                          }) => (
                            <tr key={`tr-${id}`}>
                              <Livro
                                id={id}
                                titulo={titulo}
                                formato={formato}
                                paginas={paginas}
                                tipo={tipo}
                                metadata={metadata}
                                componentes={componentes}
                              />
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  {materiaisProfessor.length > 0 && (
                    <div className="m-complementar">
                      <Typography variant="title" size={6} component="h3">
                        Materiais complementares
                      </Typography>
                      <table className="table">
                        <tbody>
                          {materiaisProfessor.map(
                            ({ id, titulo, url, tipo, etiquetaGoogleAnalytics }) => (
                              <tr key={`tr-${id}`}>
                                <MaterialComplementar
                                  id={id}
                                  titulo={titulo}
                                  url={url}
                                  tipo={tipo}
                                  etiquetaGoogleAnalytics={etiquetaGoogleAnalytics}
                                />
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {livrosAluno.length > 0 && (
        <section className="container-fluid" id="student-section">
          <div className="container d-flex fd-column">
            <div className="col-lg-8 col-md-8 offset-md-3">
              <Typography variant="title" size={6} component="h2" className="eva-margin-bottom-1-5">
                Livros do aluno
              </Typography>

              <div className="eva-card eva-bg-branco">
                <div className="eva-card-info">
                  <div className="livro">
                    <table className="table">
                      <tbody>
                        {livrosAluno.map(
                          ({
                            id,
                            titulo,
                            formato,
                            paginas,
                            tipo,
                            metadata,
                            componentes,
                          }) => (
                            <tr key={`tr-${id}`}>
                              <Livro
                                id={id}
                                titulo={titulo}
                                formato={formato}
                                paginas={paginas}
                                tipo={tipo}
                                metadata={metadata}
                                componentes={componentes}
                              />
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  {materiaisAluno.length > 0 && (
                    <div className="m-complementar">
                      <Typography variant="title" size={6} component="h3">
                        Materiais complementares
                      </Typography>

                      <table className="table">
                        <tbody>
                          {materiaisAluno.map(({ id, titulo, url, tipo, etiquetaGoogleAnalytics }) => (
                            <tr key={`tr-${id}`}>
                              <MaterialComplementar
                                id={id}
                                titulo={titulo}
                                url={url}
                                tipo={tipo}
                                etiquetaGoogleAnalytics={etiquetaGoogleAnalytics}
                              />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {curso && (
        <section
          className="container-fluid"
          id="course-section"
        >
          <div className="container d-flex fd-column">
            <div className="col-lg-8 col-md-8 offset-md-3">
              <Typography variant="title" size={6} component="h2" className="eva-margin-bottom-1-5">
                Curso sobre o material
              </Typography>

              <div className="eva-margin-bottom-1-5">
                <Image
                  remoteImage={curso.remoteImage}
                  fallbackImageUrl={curso.capa}
                  alt={curso.capaAltText}
                />
              </div>

              <Typography variant="title" size={6} component="h2" className="eva-margin-bottom-1-5">
                {curso.titulo}
              </Typography>
        
              <Typography variant="body" size={2} className="eva-margin-bottom-4">
                {curso.descricao}
              </Typography>

              <div className="d-flex jc-center">
                <Button component="a" href={curso.url} target="_blank" variant="principal" size="medium" type="button">
                  Faça grátis
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    paginaAgregadora(slug: { eq: $slug }) {
      id
      titulo
      subtitulo
      descricao
      slug
      metadata {
        capa
        capaAltText
        descricaoSeo
        tituloSeo
        remoteImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      livros {
        id
        titulo
        tipo
        formato
        paginas
        metadata {
          arquivo
          capa
          capaAltText
          etiquetaGoogleAnalytics
          remoteImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        componentes {
          nome
        }
      }
      materiais {
        id
        titulo
        tipo
        url
        etiquetaGoogleAnalytics
      }
      curso {
        capa
        capaAltText
        descricao
        titulo
        url
        remoteImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default PaginaAgregadoraPage;
