import React from "react";
import { Typography } from "@novaescola/eva-react-v2";
import Image from "../general/Image";

import { LivroProps } from "./_/types";

const Livro: React.FC<LivroProps> = ({
  titulo,
  formato,
  paginas,
  metadata,
  componentes,
}) => {
  const componentes_formatted = componentes
    .map((el) => el.nome)
    .join(", ")
    .replace(/,([^,]*)$/, " e$1");

  return (
    <td className="livro">
      <div className="row">
        <div className="col-lg-3 col-sm-auto col-5">
          <Image
            remoteImage={metadata.remoteImage}
            fallbackImageUrl={metadata.capa}
            alt={metadata.capaAltText}
          />
        </div>

        <div className="col-lg-8 col-6 eva-margin-left-2">
          <div className="d-flex fd-column h-100">
            <div className="eva-margin-bottom-auto">
              <Typography variant="body" size={1} component="h3">
                {titulo}
              </Typography>
              <Typography
                variant="body"
                size={2}
                className="eva-color-cinza-600"
              >
                Formato: {formato}
              </Typography>
              <Typography
                variant="body"
                size={2}
                className="eva-color-cinza-600"
              >
                Nº de páginas: {paginas}
              </Typography>
              {componentes && componentes.length > 0 && (
                <Typography
                  variant="body"
                  size={2}
                  className="eva-color-cinza-600 eva-tablet-and-greater-only"
                >
                  Componentes curriculares: {componentes_formatted}
                </Typography>
              )}
            </div>

            <a
              href={metadata.arquivo}
              onClick={() => typeof gtag !== "undefined" && gtag('event', 'Click', {
                "event_category": "Material Educacional",
                "event_label": `${metadata.etiquetaGoogleAnalytics}`,
              }) }
              target="_blank"
              className="eva-link-preto eva-font-18 eva-tablet-and-greater-only btn-download"
            >
              <i className="far fa-download icon-left" />
              Baixar material
            </a>
          </div>
        </div>
      </div>

      <div className="row eva-mobile-only">
        <div className="col">
          <div className="eva-margin-bottom-auto">
            {componentes && componentes.length > 0 && (
              <Typography
                variant="body"
                size={2}
                className="eva-color-cinza-600"
              >
                Componentes curriculares: {componentes_formatted}
              </Typography>
            )}
          </div>

          <div className="d-flex jc-center">
            <a
              href={metadata.arquivo}
              onClick={() => typeof gtag !== "undefined" && gtag('event', 'Click', {
                "event_category": "Material Educacional",
                "event_label": `${metadata.etiquetaGoogleAnalytics}`,
              })}
              target="_blank"
              className="eva-link-preto eva-font-18"
            >
              <i className="far fa-download icon-left" />
              Baixar material
            </a>
          </div>
        </div>
      </div>
    </td>
  );
};

export default Livro;
