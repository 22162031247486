import React from "react";
import { useLocation } from "@reach/router";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import {
  WhatsappShareButton,
  FacebookShareButton,
  PinterestShareButton
} from "react-share";

type ShareProps = {
  titulo: string;
  media: string;
};

const ShareButtons: React.FC<ShareProps> = ({ titulo, media }) => {
  const url = useLocation().href;
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click'
  });

  return (
    <div className="share-buttons ">
      <WhatsappShareButton
        url={url}
        resetButtonStyle={false}
        className="eva-btn-icon eva-btn-outline eva-btn-small"
        aria-label="Compartilhar WhatsApp"
      >
        <i className="fab fa-whatsapp"></i>
      </WhatsappShareButton>
      <FacebookShareButton
        url={url}
        resetButtonStyle={false}
        className="eva-btn-icon eva-btn-outline eva-btn-small"
        aria-label="Compartilhar Facebook"
      >
        <i className="fab fa-facebook-f"></i>
      </FacebookShareButton>
      <PinterestShareButton
        url={url}
        resetButtonStyle={false}
        media={media}
        description={titulo} 
        className="eva-btn-icon eva-btn-outline eva-btn-small"
        aria-label="Compartilhar Pinterest"
      >
        <i className="fab fa-pinterest-p"></i>
      </PinterestShareButton>
      <a
        rel="noopener"
        className="eva-btn-icon eva-btn-outline eva-btn-small"
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
        ref={setTriggerRef}
      >
        <span className="text-for-accessibility">Copiar Link</span>
        <i className="fas fa-link" />
      </a>
      {
        visible && (
          <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          Link copiado com sucesso!
        </div>
        )
      }
    </div>
  );
};

export default ShareButtons;
