import React from "react";
import { Typography } from "@novaescola/eva-react-v2";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import ShareButtons from "../general/ShareButtons";

type MenuProps = {
  secaoProfessor: boolean;
  secaoAluno: boolean;
  secaoCurso: boolean;
  titulo: string;
  media: string;
};

const Menu: React.FC<MenuProps> = ({
  secaoProfessor,
  secaoAluno,
  secaoCurso,
  titulo,
  media
}) => {

  return (
    <>
      <Typography variant="body" size={2}  className="eva-text-bold eva-text-center eva-margin-bottom-3">
        Acesso rápido
      </Typography>
      <ol className="menu-list-decimal eva-margin-bottom-4">
        <li>
          <AnchorLink to="#about-section">
            Sobre o Material
          </AnchorLink>
        </li>

        {secaoProfessor && (
          <li>
            <AnchorLink to="#teacher-section">
              Livros do professor
            </AnchorLink>
          </li>
        )}

        {secaoAluno && (
          <li>
            <AnchorLink to="#student-section">
              Livros do aluno
            </AnchorLink>
          </li>
        )}

        {secaoCurso && (
          <li>
            <AnchorLink to="#course-section">
              Curso sobre o material
            </AnchorLink>
          </li>
        )}
      </ol>

      <div className="share-box d-flex fd-column ai-center">
        <Typography variant="caption" className="eva-margin-bottom-1">
          Compartilhar:
        </Typography>
        <ShareButtons titulo={titulo} media={media} />
      </div>
    </>
  );
};

export default Menu;
