import React from "react";
import { Link } from "@novaescola/eva-react-v2";
import { MaterialComplementarProps } from "./_/types";

const MaterialComplementar: React.FC<MaterialComplementarProps> = ({
  titulo,
  url,
  etiquetaGoogleAnalytics
}) => {
  return (
    <td>
      <Link href={url}
            onClick={() => typeof gtag !== "undefined" && gtag('event', 'Click', {
              "event_category": "Material Educacional",
              "event_label": `${etiquetaGoogleAnalytics}`,
            })}
            target="_blank"
            component="a"
            variant="preto"
            className="eva-font-18 d-flex jc-space-between ai-flex-end">
        {titulo}
        <i className="far fa-download" />
      </Link>
    </td>
  );
};

export default MaterialComplementar;
