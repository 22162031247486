import React from "react";

type BreadCrumbProps = {
  items: {
    text: string;
    url?: string;
  }[];
};

const BreadCrumb: React.FC<BreadCrumbProps> = ({ items }) => {
  const uniqueString = () => Math.random().toString(36).slice(-10);

  return (
    <nav aria-label="breadcrumb" className="eva-breadcrumb">
      <ol>
        <li className="breadcrumb-item">
          <a href="https://novaescola.org.br/">Início</a>
        </li>

        {items &&
          items.map(({ text, url }) => (
            <li className="breadcrumb-item" key={uniqueString()}>
              {url ? <a href={url}>{text}</a> : text}
            </li>
          ))}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
